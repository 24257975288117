import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView";
import NotFoundView from "@/views/Errors/NotFoundView";
import { useAuthStore } from "@/store/Auth";
import ManagersView from "@/views/Staff/ManagersView";
import CuratorsView from "@/views/Staff/CuratorsView";
import MarketingView from "@/views/Staff/MarketingView";
import Author24View from "@/views/Staff/Author24View";
import OtkView from "@/views/Staff/OtkView";

const routes = [
    {
        path: "/",
        name: "events",
        meta: {
            title: "События",
            requiresAuth: true,
        },
        component: () => import("@/views/EventsView"),
    },
    {
        path: "/managers",
        name: "managers",
        component: ManagersView,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                redirect: "/managers/regulations",
            },
            {
                path: "regulations",
                name: "managers-regulations",
                meta: {
                    section: "managers",
                    type: "regulations",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/RegulationsView"),
            },
            {
                path: "education",
                name: "managers-education",
                meta: {
                    section: "managers",
                    type: "education",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/EducationView"),
            },
            {
                path: "handbook",
                name: "managers-handbook",
                meta: {
                    section: "managers",
                    type: "handbook",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/HandbookView"),
            },
            {
                path: "testing",
                name: "managers-testing",
                meta: {
                    section: "managers",
                    type: "testing",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/TestingView"),
            },
            {
                path: "faq",
                name: "managers-faq",
                meta: {
                    section: "managers",
                    type: "faq",
                    custom_access: [],
                },
                component: () => import("@/views/Staff/Directories/FaqView"),
            },
            {
                path: "faq/:id",
                name: "managers-faqItem",
                component: () => import("@/views/FAQ/FaqSingleView"),
                meta: {
                    section: "managers",
                    type: "faq-item",
                },
            },
            {
                path: "testing/:id",
                name: "managers-testItem",
                component: () => import("@/views/Testing/QuizSingleView"),
                meta: {
                    section: "managers",
                    type: "test-item",
                    custom_access: [],
                },
            },
        ],
    },
    {
        path: "/curators",
        name: "curators",
        component: CuratorsView,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                redirect: "/curators/regulations",
            },
            {
                path: "regulations",
                name: "curators-regulations",
                meta: {
                    section: "curators",
                    type: "regulations",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/RegulationsView"),
            },
            {
                path: "education",
                name: "curators-education",
                meta: {
                    section: "curators",
                    type: "education",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/EducationView"),
            },
            {
                path: "testing",
                name: "curators-testing",
                meta: {
                    section: "curators",
                    type: "testing",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/TestingView"),
            },
            {
                path: "testing/:id",
                name: "curators-testItem",
                component: () => import("@/views/Testing/QuizSingleView"),
                meta: {
                    section: "curators",
                    type: "test-item",
                    custom_access: [],
                },
            },
            {
                path: "faq",
                name: "curators-faq",
                meta: {
                    section: "curators",
                    type: "faq",
                    custom_access: [],
                },
                component: () => import("@/views/Staff/Directories/FaqView"),
            },
            {
                path: "faq/:id",
                name: "curators-faqItem",
                component: () => import("@/views/FAQ/FaqSingleView"),
                meta: {
                    section: "curators",
                    type: "faq-item",
                    custom_access: [],
                },
            },
        ],
    },
    {
        path: "/otk",
        name: "otk",
        component: OtkView,
        meta: {
            requiresAuth: true,
            custom_access: [2],
        },
        children: [
            {
                path: "",
                redirect: "/otk/regulations",
            },
            {
                path: "regulations",
                name: "otk-regulations",
                meta: {
                    section: "otk",
                    type: "regulations",
                    custom_access: [2],
                },
                component: () =>
                    import("@/views/Staff/Directories/RegulationsView"),
            },
            {
                path: "education",
                name: "otk-education",
                meta: {
                    section: "otk",
                    type: "education",
                    custom_access: [2],
                },
                component: () =>
                    import("@/views/Staff/Directories/EducationView"),
            },
            {
                path: "testing",
                name: "otk-testing",
                meta: {
                    section: "otk",
                    type: "testing",
                    custom_access: [2],
                },
                component: () =>
                    import("@/views/Staff/Directories/TestingView"),
            },
            {
                path: "testing/:id",
                name: "otk-testItem",
                component: () => import("@/views/Testing/QuizSingleView"),
                meta: {
                    section: "otk",
                    type: "test-item",
                    custom_access: [2],
                },
            },
            {
                path: "faq",
                name: "otk-faq",
                meta: {
                    section: "otk",
                    type: "faq",
                    custom_access: [2],
                },
                component: () => import("@/views/Staff/Directories/FaqView"),
            },
            {
                path: "faq/:id",
                name: "otk-faqItem",
                component: () => import("@/views/FAQ/FaqSingleView"),
                meta: {
                    section: "otk",
                    type: "faq-item",
                    custom_access: [2],
                },
            },
        ],
    },
    {
        path: "/marketing",
        name: "marketing",
        component: MarketingView,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                redirect: "/marketing/regulations",
            },
            {
                path: "regulations",
                name: "marketing-regulations",
                meta: {
                    section: "marketing",
                    type: "regulations",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/RegulationsView"),
            },
            {
                path: "education",
                name: "marketing-education",
                meta: {
                    section: "marketing",
                    type: "education",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/EducationView"),
            },
            {
                path: "testing",
                name: "marketing-testing",
                meta: {
                    section: "marketing",
                    type: "testing",
                    custom_access: [],
                },
                component: () =>
                    import("@/views/Staff/Directories/TestingView"),
            },
            {
                path: "testing/:id",
                name: "marketing-testItem",
                component: () => import("@/views/Testing/QuizSingleView"),
                meta: {
                    section: "marketing",
                    type: "test-item",
                    custom_access: [],
                },
            },
            {
                path: "faq",
                name: "marketing-faq",
                meta: {
                    section: "marketing",
                    type: "faq",
                    custom_access: [],
                },
                component: () => import("@/views/Staff/Directories/FaqView"),
            },
            {
                path: "faq/:id",
                name: "marketing-faqItem",
                component: () => import("@/views/FAQ/FaqSingleView"),
                meta: {
                    section: "marketing",
                    type: "faq-item",
                    custom_access: [],
                },
            },
        ],
    },
    {
        path: "/author24",
        name: "author24",
        component: Author24View,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                redirect: "/author24/regulations",
            },
            {
                path: "regulations",
                name: "author24-regulations",
                meta: {
                    section: "author24",
                    custom_access: ["managers"],
                    type: "regulations",
                },
                component: () =>
                    import("@/views/Staff/Directories/RegulationsView"),
            },
            {
                path: "education",
                name: "author24-education",
                meta: {
                    section: "author24",
                    custom_access: ["managers"],
                    type: "education",
                },
                component: () =>
                    import("@/views/Staff/Directories/EducationView"),
            },
            {
                path: "testing",
                name: "author24-testing",
                meta: {
                    section: "author24",
                    custom_access: ["managers"],
                    type: "testing",
                },
                component: () =>
                    import("@/views/Staff/Directories/TestingView"),
            },
            {
                path: "testing/:id",
                name: "author24-testItem",
                component: () => import("@/views/Testing/QuizSingleView"),
                meta: {
                    section: "author24",
                    custom_access: ["managers"],
                    type: "test-item",
                },
            },
            {
                path: "faq",
                name: "author24-faq",
                meta: {
                    section: "author24",
                    custom_access: ["managers"],
                    type: "faq",
                },
                component: () => import("@/views/Staff/Directories/FaqView"),
            },
            {
                path: "faq/:id",
                name: "author24-faqItem",
                component: () => import("@/views/FAQ/FaqSingleView"),
                meta: {
                    section: "author24",
                    custom_access: ["managers"],
                    type: "faq-item",
                },
            },
        ],
    },
    {
        path: "/news",
        name: "news",
        component: () => import("@/views/News/NewsListView"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/news/:id",
        name: "newsItem",
        component: () => import("@/views/News/NewsSingleView"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/search",
        name: "search",
        component: () => import("@/views/Search/SearchView"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            layout: "LoginLayout",
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFoundView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    base: "/",
    // eslint-disable-next-line
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // eslint-disable-next-line
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash,
                        behavior: "smooth",
                    });
                }, 250);
            });
        }
        return { x: 0, y: 0 }; // Go to the top of the page if no hash
    },
});
const DEFAULT_TITLE = "База знаний";
router.afterEach((to) => {
    document.title = to.meta.title
        ? to.meta.title + " :: " + DEFAULT_TITLE
        : DEFAULT_TITLE;
});

router.beforeEach((to, from, next) => {
    const auth = useAuthStore();
    const isAuthenticated = auth.authUser;
    const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
    const indexQuery = { path: "/" };
    if (reqAuth && !isAuthenticated) {
        auth.getAuthUser().then(() => {
            if (!auth.authUser) next(loginQuery);
            else next();
        });
    } else {
        if (
            !auth.isAdmin &&
            !!to.meta.section &&
            auth.userGroup.slug !== to.meta.section &&
            !to.meta.custom_access.includes(auth.userGroup.slug) &&
            !to.meta.custom_access.includes(auth.userId)
        )
            next(indexQuery);
        else next();
        let getQuiz = localStorage.getItem("quiz");
        if (getQuiz && getQuiz !== to.fullPath && to.path !== "/login") {
            // const quizQuery = { path: getQuiz, query: { redirect: to.fullPath } };
            // next(quizQuery);
            window.location.href = getQuiz;
        }
    }
});

export default router;
